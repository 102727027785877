import React, {useEffect, useState} from 'react';
import {useLogin} from "../hooks/useLogin";
import axios from "axios";

const Topics = () => {
    const [topics, setTopics] = useState();
    const [loginSuccess, handleRequestError] = useLogin();
    document.title = 'Topics';

    useEffect( () => {
        async function fetchData() {
            const responseTasks = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/tasks", {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true}
            ).catch(handleRequestError)

            let sortedTasks = responseTasks.data.sort((a,b) =>
            {
                if (Date.parse(a.dt) === Date.parse(b.dt))
                {
                    if (a.topicNum === b.topicNum)
                    {
                        return a.taskNum - b.taskNum
                    } else
                    {
                        return a.topicNum - b.topicNum
                    }
                } else
                {
                    return Date.parse(a.dt) - Date.parse(b.dt)
                }

            })

            let topics = [];
            for(let i in sortedTasks)
            {
                let currentTask = sortedTasks[i];
                if (currentTask.minutes !== -1)
                {
                    let topic = topics.find(o => o.topicName === currentTask.topicName)
                    if (topic)
                    {
                        let task = topic.tasks.find(o => o.taskName === currentTask.taskName)
                        if (task)
                        {
                            task.hours = task.hours + currentTask.hours;
                            task.minutes = task.minutes + currentTask.minutes;
                            if (task.minutes > 59)
                            {
                                task.minutes = task.minutes - 60;
                                task.hours = task.hours + 1;
                            }
                        } else
                        {
                            topic.tasks.push({taskName: currentTask.taskName, hours: currentTask.hours, minutes: currentTask.minutes})
                        }
                    } else
                    {
                        topic = {topicName: currentTask.topicName, tasks: []}
                        topics.push(topic);
                        topic.tasks.push({taskName: currentTask.taskName, hours: currentTask.hours, minutes: currentTask.minutes})
                    }
                }
            }

            setTopics([...topics.reverse()]);
        }
        fetchData();
    }, []);

    function getCountString(topic) {
        let hours = topic.tasks.reduce((acc, task) => acc + task.hours, 0);
        let minutes = topic.tasks.reduce((acc, task) => acc + task.minutes, 0);
        hours = hours + Math.floor(minutes / 60);
        minutes = minutes % 60;
        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    }

    return (
        <div style={{maxWidth: 500, margin: '0 auto'}}>
            {topics &&
                topics.map((topic) => (
                    <div className="mb-4" key={topic.topicName}>
                        <div className="fw-bold text-start mb-1">{topic.topicName}</div>
                        {topic.tasks.map((task) => (
                            <div key={task.taskName}>
                                <div className="text-start">
                                    <span>{task.taskName.charAt(0).toUpperCase() + task.taskName.slice(1) + ' ' + task.hours.toString().padStart(2, '0') + ':' + task.minutes.toString().padStart(2, '0')}</span>
                                </div>
                            </div>
                        ))
                        }
                        <div className="text-start">
                            <span>{'Итого: ' + getCountString(topic)}</span>
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default Topics;