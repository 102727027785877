import {useEffect, useRef} from "react";


export const useCompareWithPrev = (val) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = val;
    });
    const prevVal = ref.current;
    if (!prevVal) return false;
    return prevVal !== val
}