import '../../App.css';
import {useEffect, useState} from "react";
import axios from "axios";
import TasksDay from "./TasksDay";
import {useLogin} from "../../hooks/useLogin";
import {useSearchParams} from "react-router-dom";

function Tasks() {
    const [tasks, setTasks] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loginSuccess, handleRequestError] = useLogin();
    if (localStorage.getItem('hours') == null) {
        localStorage.setItem('hours', new Date().getHours().toString())
        localStorage.setItem('minutes', new Date().getMinutes().toString())
    }
    const [hours, setHours] = useState(localStorage.getItem('hours'));
    const [minutes, setMinutes] = useState(localStorage.getItem('minutes'));
    document.title = 'Tasks';

    useEffect( () => {
        let d = searchParams.get('d');
        if (d) setSearchParams({d});
        let a = searchParams.get('a');
        if (a)
        {
            setSearchParams({...searchParams, a});
        }
        let all = searchParams.get('all');
        if (all)
        {
            setSearchParams({...searchParams, all});
        }

        async function fetchData() {
            const responseTasks = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/tasks" + (all ? "/all" : ''),
                {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                        withCredentials: true}
            ).catch(handleRequestError)
            const responseDays = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/days" + (all ? "/all" : ''),
                {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
                    withCredentials: true}
            ).catch(handleRequestError)
            let days = responseDays.data;

            let sortedTasks = responseTasks.data.sort((a,b) =>
            {
                if (Date.parse(a.dt) === Date.parse(b.dt))
                {
                    if (a.topicNum === b.topicNum)
                    {
                        return a.taskNum - b.taskNum
                    } else
                    {
                        return a.topicNum - b.topicNum
                    }
                } else
                {
                    return Date.parse(b.dt) - Date.parse(a.dt)
                }

            })

            let previousDt = ''
            let previousTopicNum = ''
            let currentDate = {}
            let currentTopic = {}
            let preparedTasks = []
            for(let i in sortedTasks)
            {
                let task = sortedTasks[i]
                if (task.dt !== previousDt)
                {
                    currentDate = {dt: task.dt, topics: []}

                    let day = days.find(o => o.dt === currentDate.dt)
                    currentDate['startwork'] = day.startwork;
                    currentDate['wakeup'] = day.wakeup;
                    currentDate['description'] = day.description;
                    currentDate['dopDescription'] = day.dopDescription;

                    preparedTasks.push(currentDate)
                    previousTopicNum = ''
                    currentTopic = {}
                    previousDt = task.dt
                }
                if (task.topicNum !== previousTopicNum)
                {
                    currentTopic = {topicNum: task.topicNum, topicName: task.topicName, tasks: []}
                    currentDate.topics.push(currentTopic)
                    previousTopicNum = task.topicNum
                }
                currentDate.topics[currentDate.topics.length - 1].tasks.push({taskNum: task.taskNum, taskName: task.taskName, hours: task.hours, minutes: task.minutes})
            }

            let displayedTasks;
            if (all)
            {
                displayedTasks = preparedTasks;
            } else {
                let index = preparedTasks.findIndex(o => o.dt === d)
                if (index === -1) index = 0;
                displayedTasks = preparedTasks.slice(index, 10 + index)
            }

            if (!a)
            {
                displayedTasks.forEach((dateObject) => {
                    dateObject.topics.forEach((topic) => {
                        topic.tasks = topic.tasks.filter((task) => task.minutes !== -1)
                    });
                    dateObject.topics = dateObject.topics.filter(topic => topic.tasks.length !== 0)
                })
            }
            setTasks(displayedTasks);
        }
        fetchData();
    }, []);

    function refreshTime() {
        let h = new Date().getHours();
        let m = new Date().getMinutes();
        localStorage.setItem('hours', h);
        localStorage.setItem('minutes', m);
        setHours(h.valueOf() - 1);
        setHours(h.valueOf());
        setMinutes(m.valueOf() - 1);
        setMinutes(m.valueOf());
    }

    return (
    <div style={{maxWidth: 500, margin: '0 auto'}}>
        <div className="text-center">
            <span>{hours.toString().padStart(2, '0') + ':'
                + minutes.toString().padStart(2, '0') + ' '}
            </span>
            <i style={{cursor: 'pointer'}} className="bi bi-arrow-clockwise" onClick={refreshTime}></i>
        </div>

        {tasks &&
            tasks.map((dateObject) => (
                <TasksDay dateObject={dateObject} key={dateObject.dt} setHours={setHours} setMinutes={setMinutes}/>
            ))
        }
   </div>
  );
};

export default Tasks;
