import React, { useEffect, useState} from 'react';
import axios from "axios";
import {useAuth} from "../hooks/useAuth";
import {useLogin} from "../hooks/useLogin";


const Login = () => {
    const {signOut} = useAuth();
    const [errText, setErrText] = useState('');
    const [loginSuccess] = useLogin();

/*    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/logout", {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true});
        signOut(() => void(0));
    }, []);*/

    async function handleSubmit(usrStr) {
        const response = await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/auth/login",
            usrStr,
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                maxRedirects: 0
            }
        ).then((response) => {
                localStorage.setItem('userFullName', JSON.parse(atob(response.data.split('.')[1])).fullName)
                localStorage.setItem('token', response.data)
                loginSuccess(response.data)
            }
        )
         .catch(function (error) {
            console.log(error);
            if(error?.response.status === 400){
                setErrText('Неправильный логин или пароль')
            } else
            {
                setErrText('Сервер данных не отвечает')
            }
        });
    }

    async function handleSubmit1(e) {
        let usrStr = JSON.stringify({
                login: 'vasily',
                password: prompt()
            });
        await handleSubmit(usrStr);
    }

    async function handleSubmit2(e) {
        let usrStr = JSON.stringify({
            login: 'zhenya',
            password: prompt()
        });
        await handleSubmit(usrStr);
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <div className="text-danger mb-3">{errText}</div>
            <button className="mb-2" onClick={handleSubmit1}>Логин User 1</button>
            <button className="mb-2" onClick={handleSubmit2}>Логин User 2</button>
        </div>
    );
};

export default Login;


// const Login = () => {
//     const { setAuth } = useContext(AuthContext);
//     const userRef = useRef();
//     const errRef = useRef();
//
//     const [username, setUsername] = useState('');
//     const [password, setPassword] = useState('');
//     const [errMsg, setErrMsg] = useState('');
//     const [success, setSuccess] = useState(false);
//
//     useEffect(() => {
//         setErrMsg('');
//     }, [username, password])
//
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//
//         try {
//             const response = await axios.post(LOGIN_URL,
//                 JSON.stringify({ username, password }),
//                 {
//                     headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token') },
//                     withCredentials: true
//                 }
//             );
//             console.log(JSON.stringify(response));
//
//             setAuth(true);
//             setUsername('');
//             setPassword('');
//             setSuccess(true);
//         } catch (err) {
//             if (!err?.response) {
//                 setErrMsg('No Server Response');
//             } else if (err.response?.status === 400) {
//                 setErrMsg('Missing Username or Password');
//             } else if (err.response?.status === 401) {
//                 setErrMsg('Incorrect username or password');
//             } else {
//                 setErrMsg('Internal error');
//             }
//         }
//     }
//
//     return (
//         <>
//             {success ? (
//                 <section>
//                     <h1>You are logged in!</h1>
//                     <br />
//                     <p>
//                         <a href="#">Go to Home</a>
//                     </p>
//                 </section>
//             ) : (
//                 <section>
//                     <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
//                     <h1>Sign In</h1>
//                     <form onSubmit={handleSubmit}>
//                         <label htmlFor="username">Username:</label>
//                         <input
//                             type="text"
//                             id="username"
//                             ref={userRef}
//                             autoComplete="off"
//                             onChange={(e) => setUser(e.target.value)}
//                             value={user}
//                             required
//                         />
//
//                         <label htmlFor="password">Password:</label>
//                         <input
//                             type="password"
//                             id="password"
//                             onChange={(e) => setPwd(e.target.value)}
//                             value={pwd}
//                             required
//                         />
//                         <button>Sign In</button>
//                     </form>
//                     <p>
//                         Need an Account?<br />
//                         <span className="line">
//                             {/!*put router link here*!/}
//                             <a href="#">Sign Up</a>
//                         </span>
//                     </p>
//                 </section>
//             )}
//         </>
//
// const Login = () => {
//     const {isAuth, setIsAuth} = useContext(AuthContext);
//
//     const login = event => {
//         event.preventDefault();
//         setIsAuth(true);
//         localStorage.setItem('auth', 'true')
//     }
//
//     return (
//         <div>
//             <h1>Страница для логина</h1>
//             <form onSubmit={login}>
//                 <input type="text" placeholder="Введите логин"/>
//                 <input type="password" placeholder="Введите пароль"/>
//                 <button>Войти</button>
//             </form>
//         </div>
//     );
// };
//
// export default Login;

