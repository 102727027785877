import React, {useEffect, useState} from 'react';
import {useLogin} from "../../hooks/useLogin";
import axios from "axios";
import Month from "./Month";

const Months = () => {
    const [months, setMonths] = useState();
    const [loginSuccess, handleRequestError] = useLogin();
    document.title = 'Months';

    useEffect( () => {
        async function fetchData() {
            console.log("111")
            console.log(handleRequestError)
            const responseMonths = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/months", {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true}
            ).catch(handleRequestError);
            console.log("222")

            let sortedMonths = responseMonths.data.sort((a,b) => Date.parse(b.dtFrom) - Date.parse(a.dtFrom));
            setMonths([...sortedMonths]);
        }
        fetchData();
    }, []);

    return (
        <div style={{maxWidth: 700, margin: '0 auto'}}>
            {months &&
                months.map((month) => (
                    <Month key={month.monthId} month={month}/>
                ))
            }
        </div>
    );
};

export default Months;