import dateFormat from "dateformat";


export const convertDate = (strDate) => {
      let days = [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота'
    ];
    return days[new Date(Date.parse(strDate.substring(0,10))).getDay()] + '\n' +
        dateFormat(Date.parse(strDate.substring(0,10)), 'dd.mm.yyyy');
}