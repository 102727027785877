import {Outlet} from 'react-router-dom';
import NavBar from "./NavBar";
import {useAuth} from "../hooks/useAuth";

const Layout = () => {
    const {user} = useAuth();

    return (
        <div className="container" style={{fontSize: '1.3rem'}}>
            { user ? <NavBar/> : <></>}
            <Outlet/>
        </div>
    )
}

export default Layout;
