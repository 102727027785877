import React, {useEffect, useRef} from 'react';
import {blurOnKeyPress} from "../../utils/blurOnKeyPress";
import axios from "axios";
import FlatInput from "../UI/FlatInput";
import {useLogin} from "../../hooks/useLogin";

const Week = ({week}) => {
    const dtFromRef = useRef();
    const dtToRef = useRef();
    const textareaRef = useRef();
    const [loginSuccess, handleRequestError] = useLogin();

    useEffect(() => {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }, [])

    async function updateDescription(e) {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/weeks/week/update",
            JSON.stringify({
                weekId: week.weekId,
                description: e.target.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    async function updateDtFrom() {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/weeks/week/update",
            JSON.stringify({
                weekId: week.weekId,
                dtFrom: dtFromRef.current.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    async function updateDtTo() {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/weeks/week/update",
            JSON.stringify({
                weekId: week.weekId,
                dtTo: dtToRef.current.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    async function addWeek()
    {
        let from = prompt();
        let to = prompt();
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/weeks/week/add",
            JSON.stringify({
                dtFrom: from,
                dtTo: to
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
        window.location.reload();
    }

    return (
        <div className="mb-4">
            <div className="fw-bold text-start mb-1">
                {(week.numberOfDays) + ' дней ('}
                <FlatInput ref={dtFromRef} value={week.dtFrom.slice(-2) + '.' + week.dtFrom.slice(-5).slice(0, 2)} onBlur={updateDtFrom} fwBold noPadRight/>
                {' - '}
                <FlatInput ref={dtToRef} value={week.dtTo.slice(-2) + '.' + week.dtTo.slice(-5).slice(0, 2)} onBlur={updateDtTo} fwBold/>
                {')'}
            </div>
            <div className="fw-bold text-start"  style={{fontSize: '1.1rem'}}>
                <span className="me-3">{Math.round((week.minutes / 60) * 10) / 10}</span>
                <span className="me-3">{Math.round((week.minutes / week.numberOfDays * 7 / 60) * 10) / 10}</span>
                <span className="me-3" onClick={addWeek}>{Math.round((week.minutes / week.numberOfDays * 7 / 5 / 60) * 10) / 10}</span>
                <span className="me-3">{Math.round((week.minutes / week.numberOfDays / 60) * 10) / 10}</span>
            </div>
            <textarea ref={textareaRef} className="noBorderInput form-control d-inline"
                      style={{fontSize: '1.1rem'}}
                      defaultValue={week.description}
                      onBlur={updateDescription}
                      onKeyPress={blurOnKeyPress}
            />
            {week.dopDescriptions &&
                week.dopDescriptions.map((dopDescription) => (
                    <div style={{fontSize: '1rem'}} key={dopDescription}>{'--' + dopDescription}</div>
                ))
            }
        </div>
    );
};

export default Week;