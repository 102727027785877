import React, {useEffect, useRef} from 'react';
import FlatInput from "../UI/FlatInput";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";
import {useCompareWithPrev} from "../../hooks/useCompareWithPrev";

const MinutesInput = ({dt, topicNum, task, setTask}) => {
    const minutesRef = useRef();
    const [loginSuccess, handleRequestError] = useLogin();
    const taskChanged = useCompareWithPrev(task);


    const updateMinutes = async () => {
        if (taskChanged) {
            await axios.post(
                process.env.REACT_APP_API_BASE_URL + ":8000/tasks/task/minutes/update",
                JSON.stringify({
                    dt: dt,
                    topicNum: topicNum,
                    taskNum: task.taskNum,
                    minutes: task.minutes
                }),
                {
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                    
                    maxRedirects: 0
                }
            ).catch(handleRequestError);
        }
    }

    useEffect( () => {
        updateMinutes();
    }, [task]);

    async function handleBlur() {
        task.minutes = minutesRef.current.value;
        setTask({...task});
    }

    return (
        <FlatInput ref={minutesRef} value={task.minutes.toString().padStart(2, '0')} onBlur={handleBlur}/>
    );
};

export default MinutesInput;