import React, {useEffect, useState} from 'react';
import {useLogin} from "../../hooks/useLogin";
import axios from "axios";
import Quarter from "./Quarter";

const Quarters = () => {
    const [quarters, setQuarters] = useState();
    const [loginSuccess, handleRequestError] = useLogin();
    document.title = 'Quarters';

    useEffect( () => {
        async function fetchData() {
            const responseQuarters = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/quarters", {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true}
            ).catch(handleRequestError);

            let sortedQuarters = responseQuarters.data.sort((a,b) => Date.parse(b.dtFrom) - Date.parse(a.dtFrom));
            setQuarters([...sortedQuarters]);
        }
        fetchData();
    }, []);

    return (
        <div style={{maxWidth: 700, margin: '0 auto'}}>
            {quarters &&
                quarters.map((quarter) => (
                    <Quarter key={quarter.quarterId} quarter={quarter}/>
                ))
            }
        </div>
    );
};

export default Quarters;