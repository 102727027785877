import React, {useRef} from 'react';
import FlatInput from "../UI/FlatInput";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";

const StartworkInput = (props) => {
    const startworkRef = useRef();
    const [loginSuccess, handleRequestError] = useLogin();

    async function handleBlur(usrStr) {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/days/startwork/update",
            JSON.stringify({
                dt: props.dt,
                startwork: startworkRef.current.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }


    return (
        <FlatInput ref={startworkRef} value={props.startwork} onBlur={handleBlur}/>
    );
};

export default StartworkInput;