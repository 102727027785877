import React, {useEffect, useRef} from 'react';
import FlatInput from "../UI/FlatInput";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";
import {useCompareWithPrev} from "../../hooks/useCompareWithPrev";

const HoursInput = ({dt, topicNum, task, setTask, hoursUpdate}) => {
    const hoursRef = useRef();
    const [loginSuccess, handleRequestError] = useLogin();

    const taskChanged = useCompareWithPrev(task);

    const updateHours = async () => {
        if (taskChanged) {
            await axios.post(
                process.env.REACT_APP_API_BASE_URL + ":8000/tasks/task/hours/update",
                JSON.stringify({
                    dt: dt,
                    topicNum: topicNum,
                    taskNum: task.taskNum,
                    hours: task.hours
                }),
                {
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                    
                    maxRedirects: 0
                }
            ).catch(handleRequestError);
        }
    }

    useEffect( () => {
        updateHours();
    }, [task]);

    async function handleBlur() {
        task.hours = hoursRef.current.value;
        setTask({...task});
    }


    return (
        <FlatInput ref={hoursRef} value={task.hours.toString().padStart(2, '0')} onBlur={handleBlur} noPadRight/>
    );
};

export default HoursInput;