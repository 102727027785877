import * as React from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import "./App.css";
import {useEffect} from "react";
import Tasks from "./components/tasks/Tasks";
import Days from "./components/days/Days";
import Login from "./components/Login";
import RequireAuth from "./auth/RequireAuth";
import Layout from "./components/Layout";
import {useLogin} from "./hooks/useLogin";
import ServerError from "./components/ServerError";
import PageNotFound from "./components/PageNotFound";
import Topics from "./components/Topics";
import Weeks from "./components/weeks/Weeks";
import Months from "./components/months/Months";
import Quarters from "./components/quarters/Quarters";
import Years from "./components/years/Years";

function App() {
    const [loginSuccess] = useLogin();

    useEffect(() => {
        loginSuccess(localStorage.getItem('userFullName') || 'Пользователь загружается...');
    }, []);

    return (
      <div className="App">
          <Routes>
              <Route path="/" element={<Layout/>}>
                  <Route path="tasks/*" element={<RequireAuth><Tasks/></RequireAuth>} />
                  <Route path="" element={<RequireAuth><Tasks/></RequireAuth>} />
                  <Route path="days" element={<RequireAuth><Days/></RequireAuth>} />
                  <Route path="weeks" element={<RequireAuth><Weeks/></RequireAuth>} />
                  <Route path="months" element={<RequireAuth><Months/></RequireAuth>} />
                  <Route path="quarters" element={<RequireAuth><Quarters/></RequireAuth>} />
                  <Route path="years" element={<RequireAuth><Years/></RequireAuth>} />
                  <Route path="topics" element={<RequireAuth><Topics/></RequireAuth>} />
                  <Route path="login" element={<Login/>} />
                  <Route path="server_error" element={<ServerError/>} />
                  <Route path="*" element={<PageNotFound/>} />
              </Route>
          </Routes>
      </div>
  );
}

export default App;