import React, {useEffect, useRef} from 'react';
import {blurOnKeyPress} from "../../utils/blurOnKeyPress";
import axios from "axios";
import FlatInput from "../UI/FlatInput";
import {useLogin} from "../../hooks/useLogin";

const Year = ({year}) => {
    const dtFromRef = useRef();
    const dtToRef = useRef();
    const textareaRef = useRef();
    const [loginSuccess, handleRequestError] = useLogin();

    useEffect(() => {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }, [])

    async function updateDescription(e) {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/years/year/update",
            JSON.stringify({
                yearId: year.yearId,
                description: e.target.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    async function updateDtFrom() {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/years/year/update",
            JSON.stringify({
                yearId: year.yearId,
                dtFrom: dtFromRef.current.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    async function updateDtTo() {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/years/year/update",
            JSON.stringify({
                yearId: year.yearId,
                dtTo: dtToRef.current.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    async function addYear()
    {
        let from = prompt();
        let to = prompt();
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/years/year/add",
            JSON.stringify({
                dtFrom: from,
                dtTo: to
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
        window.location.reload()
    }

    return (
        <div className="mb-4">
            <div className="fw-bold text-start mb-1">
                {(year.numberOfDays) + ' дней ('}
                <FlatInput ref={dtFromRef} value={year.dtFrom.slice(-2) + '.' + year.dtFrom.slice(-5).slice(0, 2)} onBlur={updateDtFrom} fwBold noPadRight/>
                {' - '}
                <FlatInput ref={dtToRef} value={year.dtTo.slice(-2) + '.' + year.dtTo.slice(-5).slice(0, 2)} onBlur={updateDtTo} fwBold/>
                {')'}
            </div>
            <div className="fw-bold text-start" style={{fontSize: '1.1rem'}}>
                <span className="me-3">{Math.round((year.minutes / 60) * 10) / 10}</span>
                <span className="me-3">{Math.round((year.minutes / year.numberOfDays * 7 / 60) * 10) / 10}</span>
                <span className="me-3" onClick={addYear}>{Math.round((year.minutes / year.numberOfDays * 7 / 5 / 60) * 10) / 10}</span>
                <span className="me-3">{Math.round((year.minutes / year.numberOfDays / 60) * 10) / 10}</span>
            </div>
            <textarea ref={textareaRef} className="noBorderInput form-control d-inline"
                      style={{fontSize: '1.1rem'}}
                      defaultValue={year.description}
                      onBlur={updateDescription}
                      onKeyPress={blurOnKeyPress}
            />
            <div className="text-start" style={{fontSize: '1rem'}}>
                <span className="me-3">{year.countCm + '(' + Math.round( year.countCm * 10 / year.numberOfDays * 7) / 10 + ') цм, '
                    + year.countGym + '(' + Math.round(year.countGym * 10 / year.numberOfDays * 7) / 10 + ') залов, ' +
                    year.countDayOff + '(' + Math.round(year.countDayOff / year.numberOfDays * 10 * 7) / 10 + ') выходных, '
                    + year.countZeros + '(' + Math.round(year.countZeros * 10 / year.numberOfDays * 7) / 10 + ') нулей'}</span>
                <br/>
                <span className="me-3">{year.countAlco + '(' + Math.round(year.countAlco * 10 / year.numberOfDays * 7) / 10 + ') алко, '
                    + year.countMinus + '(' + Math.round(year.countMinus * 10 / year.numberOfDays * 30) / 10 + ') минус, ' +
                    year.countSteps + '(' + Math.round(year.countSteps * 10 / year.numberOfDays * 7) / 10 + ') ш, '
                    + year.countLstn + '(' + Math.round(year.countLstn * 10 / year.numberOfDays * 7) / 10 + ') л'}</span>
            </div>
        </div>
    );
};

export default Year;