import React, {useEffect, useRef} from 'react';
import {blurOnKeyPress} from "../../utils/blurOnKeyPress";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";

const DayNotes = ({dt, notes, all}) => {
    const [loginSuccess, handleRequestError] = useLogin();
    const textareaRef = useRef();

    useEffect(() => {
        if (!all)
        {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
    }, [])

    async function updateNotes(e) {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/days/notes/update",
            JSON.stringify({
                dt: dt,
                notes: e.target.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    if (all)
    {
        return (
            <textarea ref={textareaRef} rows="3" className="noBorderInput form-control d-inline"
                      style={{fontSize: '1rem'}}
                      defaultValue={notes}
                      onBlur={updateNotes}
                      onKeyPress={blurOnKeyPress}/>
        )
    } else
    {
        return (
            <textarea ref={textareaRef} className="noBorderInput form-control d-inline"
                      style={{fontSize: '1rem'}}
                      defaultValue={notes}
                      onBlur={updateNotes}
                      onKeyPress={blurOnKeyPress}/>
        )
    }
};

export default DayNotes;