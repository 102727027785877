import React, {useRef} from 'react';
import FlatInput from "../UI/FlatInput";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";

const WakeupInput = (props) => {
    const wakeupRef = useRef();
    const [loginSuccess, handleRequestError] = useLogin();

    async function handleBlur(usrStr) {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/days/wakeup/update",
            JSON.stringify({
                dt: props.dt,
                wakeup: wakeupRef.current.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }


    return (
        <FlatInput ref={wakeupRef} value={props.wakeup} onBlur={handleBlur} noPadRight/>
    );
};

export default WakeupInput;