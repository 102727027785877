import React, {useEffect, useState} from 'react';
import {useLogin} from "../../hooks/useLogin";
import axios from "axios";
import Year from "./Year";

const Years = () => {
    const [years, setYears] = useState();
    const [loginSuccess, handleRequestError] = useLogin();
    document.title = 'Years';

    useEffect( () => {
        async function fetchData() {
            const responseYears = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/years", {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true}
            ).catch(handleRequestError);

            let sortedYears = responseYears.data.sort((a,b) => Date.parse(b.dtFrom) - Date.parse(a.dtFrom));
            setYears([...sortedYears]);
        }
        fetchData();
    }, []);

    return (
        <div style={{maxWidth: 700, margin: '0 auto'}}>
            {years &&
                years.map((year) => (
                    <Year key={year.yearId} year={year}/>
                ))
            }
        </div>
    );
};

export default Years;