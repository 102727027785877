import {useAuth} from "./useAuth";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";

export const useLogin = () => {
    const {signIn, signOut} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || location.pathname;

    const loginSuccess = (userFullName) => {
        signIn(localStorage.getItem('userFullName'), () => navigate(fromPage, {replace: true}))
    }

    const handleRequestError = (error) => {
        console.log(error?.response?.data?.error)
        if (error?.response?.status === 403) {
            signOut(() => navigate('/login', {state: {from: location}}));
        } else
        {
            navigate('/server_error');
        }
    }

    return [loginSuccess, handleRequestError];
}