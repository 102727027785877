import React, {useEffect, useState} from 'react';
import {blurOnKeyPress} from "../../utils/blurOnKeyPress";

const FlatInput = React.forwardRef((props, ref) => {
    const [textValue, setTextValue] = useState(props.value);

    useEffect(() => {
        setTextValue(props.value);
    }, [props.value]);

    const stylesWrapper = {
        display: 'inline-block',
        position: 'relative'
    }

    const stylesSpan = {
        display: 'inline-block',
        visibility: 'hidden',
        whiteSpace: 'pre'
    }

    const stylesInput = {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '100%'
    }

    if (props.fwBold)
    {
        stylesInput.fontWeight = 700;
    }

    return (
        <div style={stylesWrapper}>
            <span className="noBorderInput" style={stylesSpan}>{textValue +(props.noPadRight ? '' : 'l')}</span>
            <input ref={ref}
                   type="text" className="noBorderInput form-control d-inline"
                   onChange={(e) => setTextValue(e.target.value)}
                   style={stylesInput}
                   value={textValue}
                   onBlur={props.onBlur}
                   onKeyPress={blurOnKeyPress}
            />
        </div>
    );
});

export default FlatInput;