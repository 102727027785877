import React, {useState} from 'react';
import {convertDate} from "../../utils/convertDate";
import TopicInput from "./TopicInput";
import WakeupInput from "./WakeupInput";
import StartworkInput from "./StartworkInput";
import Task from "./Task";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";

const TasksDay = ({setHours, setMinutes, ...props}) => {
    const [dateObject, setDateObject] = useState(props.dateObject);
    const [loginSuccess, handleRequestError] = useLogin();

    async function addTask(topicNum) {
        let newTaskName = prompt();

        const response = await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/tasks/task/add",
            JSON.stringify({
                dt: dateObject.dt,
                topicNum: topicNum,
                taskName: newTaskName
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);

        let task = response.data;
        let topic = dateObject.topics.find(o => o.topicNum === topicNum)
        let existedTask = topic.tasks.find(o => o.taskName === newTaskName)
        if (!existedTask)
        {
            topic.tasks.push(task);
            setDateObject({...dateObject});
        }
    }

    async function addTopic() {
        let newTopicName = prompt();

        const response = await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/tasks/topic/add",
            JSON.stringify({
                dt: dateObject.dt,
                topicName: newTopicName
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);

        let task = response.data;
        let existedTopic = dateObject.topics.find(o => o.topicName === newTopicName)
        if (!existedTopic)
        {
            let topic = {topicNum: task.topicNum, topicName: task.topicName, tasks: [task]}
            dateObject.topics.push(topic);
            setDateObject({...dateObject});
        }
    }

    return (
        <div className="mb-4">
            <div className="fw-bold text-start mb-1">{convertDate(dateObject.dt)}</div>
            <div className="text-start">
                <span>{'Встал в '}</span>
                <WakeupInput dt={dateObject.dt} wakeup={(dateObject.wakeup || '')}/>
                <span>{', начал работу в '}</span>
                <StartworkInput dt={dateObject.dt} startwork={(dateObject.startwork || '')}/>
            </div>
            {dateObject.topics.map((topic, index) => (
                <div key={topic.topicNum}>
                    <div className="text-start">
                        <span onClick={addTopic}>{(index + 1) + '. '}</span>
                        <TopicInput dt={dateObject.dt} topicNum={topic.topicNum} topicName={topic.topicName}/>
                        <i className="bi bi-plus-lg" onClick={() => addTask(topic.topicNum)}></i>
                    </div>
                    <div className="text-start">
                        <span>
                          {topic.tasks.map((task) =>
                              <Task key={task.taskNum} dt={dateObject.dt}
                                    topicNum={topic.topicNum} task={task} setHours={setHours} setMinutes={setMinutes}/>
                          )}
                        </span>
                    </div>
                </div>
            ))
            }
            <div className="text-start">--</div>
            <div className="text-start">{dateObject.dopDescription || ''}</div>
            <div className="text-start">--</div>
            <div className="text-start">{dateObject.description || ''}</div>
        </div>
    );
};

export default TasksDay;