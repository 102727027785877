import React from 'react';
import {blurOnKeyPress} from "../../utils/blurOnKeyPress";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";

const DayCm = ({dt, cm, dayOff}) => {
    const [loginSuccess, handleRequestError] = useLogin();

    async function updateCm(e) {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/days/cm/update",
            JSON.stringify({
                dt: dt,
                stringValue: e.target.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    return (
        <input type="text" className="noBorderInput form-control d-inline"
               style={{fontSize: '1rem'}}
               defaultValue={dayOff ? '-' : (cm ? '+' : (cm === false ? '0' : ''))}
               onBlur={updateCm}
               onKeyPress={blurOnKeyPress}/>
    );
};

export default DayCm;