import React, {useEffect, useState} from 'react';
import {useLogin} from "../../hooks/useLogin";
import axios from "axios";
import Week from "./Week";

const Weeks = () => {
    const [weeks, setWeeks] = useState();
    const [loginSuccess, handleRequestError] = useLogin();
    document.title = 'Weeks';

    useEffect( () => {
        async function fetchData() {
            const responseWeeks = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/weeks", {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true}
            ).catch(handleRequestError);

            let sortedWeeks = responseWeeks.data.sort((a,b) => Date.parse(b.dtFrom) - Date.parse(a.dtFrom));
            setWeeks([...sortedWeeks]);
        }
        fetchData();
    }, []);

    return (
        <div style={{maxWidth: 700, margin: '0 auto'}}>
            {weeks &&
                weeks.map((week) => (
                    <Week key={week.weekId} week={week}/>
                ))
            }
        </div>
    );
};

export default Weeks;