import React from 'react';

const DayHours = ({tasks, dayMinutes, dayOff, dt}) => {

    let taskHours;
    if (tasks.length)
    {
        let taskMinutes = tasks.filter(task => task.minutes !== -1)
                                .reduce((minutes, task) => minutes + (parseInt(task.hours) * 60 + parseInt(task.minutes)), 0);
        taskHours = Math.round((taskMinutes / 60) * 10) / 10;
    }

    let currentDate = new Date();
    currentDate = currentDate.getFullYear() + '-' +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') + '-' +
        currentDate.getDate().toString().padStart(2, '0');
    if (dt === currentDate)
    {
        return <span>{taskHours || '0'}</span>
    }

    if (dayMinutes !== 0 && !dayOff)
    {
        if (!tasks.length && !dayMinutes) return <div className="text-danger"><div>{'0'}</div><span>Провал</span></div>;
    }

    let hours = Math.round((dayMinutes / 60) * 10) / 10;
    let hoursString = (taskHours || '0') + (hours ? '(' + hours + ')' : '');
    let hoursToRate = taskHours || hours;
    if (dayOff) return <span>{hoursString === '' ? '0' : hoursString}</span>;
    if (hoursToRate < 1.8) return <div className="text-danger"><div>{hoursString}</div><span>Провал</span></div>;
    if (hoursToRate < 3.8) return <div style={{color: '#ff008c'}}><div>{hoursString}</div><span>Плохо</span></div>;
    if (hoursToRate < 6.8) return <div className="text-warning"><div>{hoursString}</div><span>Удовл</span></div>;
    if (hoursToRate < 8.8) return <div className="text-primary"><div>{hoursString}</div><span>Хорош</span></div>;
    if (hoursToRate >= 8.8) return <div style={{color: '#198754'}}><div>{hoursString}</div><span>Отличн</span></div>;
};

export default DayHours;