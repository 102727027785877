import React from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import {useAuth} from "../hooks/useAuth";

const NavBar = () => {
    const {user, signOut} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    async function logout() {
        signOut(() => navigate('/login', {state: {from: location}}));
    }

    return (
        <div className="d-flex mt-3 mb-4" style={{maxWidth: 500, margin: '0 auto'}}>
            <Link className="me-3" to={"/tasks"}>Tasks</Link>
            <Link className="me-3" to={"/days"}>Days</Link>
            <Link className="me-3" to={"/weeks"}>Weeks</Link>
            <Link className="me-3" to={"/months"}>M</Link>
            <Link className="me-3" to={"/quarters"}>Q</Link>
            <Link className="me-3" to={"/years"}>Y</Link>
            <Link className="me-3" to={"/topics"}>T</Link>
            <Link className="me-3" to="#" onClick={logout}>{user}</Link>
        </div>
    );
};

export default NavBar;