import '../../App.css';
import {Container, Table} from "react-bootstrap";
import {useEffect, useState} from "react";
import {convertDate} from "../../utils/convertDate";
import {useLogin} from "../../hooks/useLogin";
import axios from "axios";
import DayHours from "./DayHours";
import {useSearchParams} from "react-router-dom";
import DayDescription from "./DayDescription";
import DayDopDescription from "./DayDopDescription";
import DayNotes from "./DayNotes";
import DayCm from "./DayCm";
import DayDop from "./DayDop";
import DaySl from "./DaySl";

function Days() {
    const [days, setDays] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [tasks, setTasks] = useState();
    const [loginSuccess, handleRequestError] = useLogin();
    document.title = 'Days';

    useEffect( () => {
        let all = searchParams.get('all');

        async function fetchData() {
            const responseDays = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/days" + (all ? "/all" : ''),
                {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true}
            ).catch(handleRequestError)

            const responseTasks = await axios.get(process.env.REACT_APP_API_BASE_URL + ":8000/tasks" + (all ? "/all" : ''),
                {headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true}
            ).catch((error) => handleRequestError(error))

            let sortedDays = responseDays.data.sort((a,b) => Date.parse(b.dt) - Date.parse(a.dt));
            let sortedTasks = responseTasks.data.sort((a,b) => Date.parse(b.dt) - Date.parse(a.dt));
            if (all)
            {
                setSearchParams({...searchParams, all});
                setDays(sortedDays);
                setTasks(sortedTasks);
            } else {
                setDays(sortedDays.slice(0, 100));
                setTasks(sortedTasks.slice(0, 1000));
            }
        }
        fetchData();
    }, []);


    return (
    <div>
      <Container>
        <Table bordered size="sm" className="mt-4" style={{fontSize: '0.9rem'}}>
          <thead>
            <tr>
              <th className="text-center">Дата</th>
              <th className="text-center">Часы</th>
              <th className="text-center">цм</th>
              <th className="text-center">д</th>
              <th className="text-center">шл</th>
              <th className="text-center">Описание</th>
              <th className="text-center">Дополнительное дело</th>
              <th className="text-center">notes</th>
            </tr>
          </thead>
          <tbody>
            {days &&
              days.map((day) => (
                <tr key={day.dt}>
                  <td className="text-start p-2"  style={{maxWidth: '130px'}}>{convertDate(day.dt)}</td>
                  <td className="p-1 pt-2 text-center fw-bold" style={{width: '75px'}}>
                      <DayHours tasks={tasks.filter(o => o.dt === day.dt)} dayMinutes={day.minutes} dayOff={day.dayOff} dt={day.dt}/>
                  </td>
                  <td className="px-2 pt-2 text-center" style={{width: '35px'}}>
                      <DayCm dt={day.dt} cm={day.cm} dayOff={day.dayOff}/>
                  </td>
                  <td className="px-2 pt-2 text-center" style={{width: '35px'}}>
                      <DayDop dt={day.dt} dop={day.dop} gym={day.gym} result={day.result}/>
                  </td>
                  <td className="px-2 pt-2 text-center" style={{width: '35px'}}>
                      <DaySl dt={day.dt} steps={day.steps} lstn={day.lstn} alco={day.alco}/>
                  </td>
                  <td className="text-start p-2">
                    <DayDescription dt={day.dt} description={day.description} all={searchParams.get('all')}/>
                  </td>
                  <td className="text-start p-2">
                      <DayDopDescription dt={day.dt} dopDescription={day.dopDescription} all={searchParams.get('all')}/>
                  </td>
                  <td className="text-start p-2">
                      <DayNotes dt={day.dt} notes={day.notes} all={searchParams.get('all')}/>
                  </td>
                </tr>
              ))}

          </tbody>
        </Table>
      </Container>
    </div>
    );
}

export default Days;
