import React, {useState} from 'react';
import TaskInput from "./TaskInput";
import HoursInput from "./HoursInput";
import MinutesInput from "./MinutesInput";

const Task = ({dt, topicNum, setHours, setMinutes, ...props}) => {
    const [task, setTask] = useState(props.task);

    function addTime()  {
        let h1 = parseInt(localStorage.getItem('hours'));
        let m1 = parseInt(localStorage.getItem('minutes'));
        if (h1 < 4) h1 = h1 + 24;

        let h2 = new Date().getHours();
        let m2 = new Date().getMinutes();

        localStorage.setItem('hours', h2);
        localStorage.setItem('minutes', m2);
        setHours(h2.valueOf());
        setMinutes(m2.valueOf());

        if (h2 < 4) h2 = h2 + 24;

        if (m1 > m2) {
            m2 = m2 + 60;
            h1 = h1 + 1;
        }

        task.hours = parseInt(task.hours) + (h2 - h1);
        task.minutes = parseInt(task.minutes) + (m2 - m1)   ;

        if (task.minutes > 59) {
            task.minutes = task.minutes - 60;
            task.hours = task.hours + 1;
        }

        setTask({...task});
    }

    return (
        <div className="text-start ms-4">
            <span style={{cursor: 'pointer'}} onClick={addTime}>{'- '}</span>
            <TaskInput dt={dt} topicNum={topicNum} task={task} setTask={setTask}/>
            <HoursInput dt={dt} topicNum={topicNum} task={task} setTask={setTask}/>
            <span>{':'}</span>
            <MinutesInput dt={dt} topicNum={topicNum} task={task} setTask={setTask}/>
        </div>
    );
};

export default Task;