import React from 'react';
import {blurOnKeyPress} from "../../utils/blurOnKeyPress";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";

const DayDop = ({dt, dop, gym, result}) => {
    const [loginSuccess, handleRequestError] = useLogin();

    async function updateDop(e) {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/days/dop/update",
            JSON.stringify({
                dt: dt,
                stringValue: e.target.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    return (
        <textarea rows="2" className="noBorderInput form-control d-inline"
                  style={{resize: 'none', fontSize: '1rem', width: '70%'}}
                  defaultValue={((dop ? '+' : dop === false ? '0' : '') + (gym ? 'з' : '') + ((result === 'Минус' || result?.slice(-5) === 'Минус') ? 'м' : ''))}
                  onBlur={updateDop}
                  onKeyPress={blurOnKeyPress}/>
    );
};

export default DayDop;