import React from 'react';
import {blurOnKeyPress} from "../../utils/blurOnKeyPress";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";

const DaySl = ({dt, steps, lstn, alco}) => {
    const [loginSuccess, handleRequestError] = useLogin();

    async function updateSl(e) {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/days/sl/update",
            JSON.stringify({
                dt: dt,
                stringValue: e.target.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
    }

    return (
        <textarea cols="1" rows="2" className="noBorderInput form-control d-inline"
                  style={{resize: 'none', fontSize: '1rem', width: '90%'}}
                  defaultValue={(steps ? 'ш' : '') + (lstn ? 'л' : '') + (alco ? 'а' : '')}
                  onBlur={updateSl}
                  onKeyPress={blurOnKeyPress}/>
    );
};

export default DaySl;