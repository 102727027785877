import React, {useRef} from 'react';
import FlatInput from "../UI/FlatInput";
import axios from "axios";
import {useLogin} from "../../hooks/useLogin";

const TaskInput = ({dt, topicNum, task, setTask}) => {
    const taskNameRef = useRef();
    const [loginSuccess, handleRequestError] = useLogin();

    async function handleBlur() {
        await axios.post(
            process.env.REACT_APP_API_BASE_URL + ":8000/tasks/task/update",
            JSON.stringify({
                dt: dt,
                topicNum: topicNum,
                taskNum: task.taskNum,
                taskName: taskNameRef.current.value
            }),
            {
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem('token')}, withCredentials: true,
                
                maxRedirects: 0
            }
        ).catch(handleRequestError);
        task.taskName = taskNameRef.current.value;
        setTask({...task});
    }


    return (
        <FlatInput ref={taskNameRef} value={task.taskName} onBlur={handleBlur}/>
    );
};

export default TaskInput;